@import 'app.scss';
.centered {
  text-align: center;

  .text {
    margin: 0 auto;
  }
}

.title {
  white-space: pre-line; // title can be text-area in Prepr (ex. text-section) https://esites.atlassian.net/browse/VIT-355
}

.subtitle {
  @include typography-heading-4;

  font-weight: 500;
}

.ctaButton {
  margin-top: spacing(3);

  @include from-breakpoint(lg) {
    margin-top: spacing(6);
  }
}

.content {
  margin-top: spacing(4);
}
